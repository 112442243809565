import http from "./http";

export const GetBanks = async() => {
    return await http.get("/banks");
};

export const Enquiry = async data => {
    return await http.post("/enquire", data);
};

export const AddBankAccount = async data => {
    return await http.post("/bank-account", data);
};

export const UpdateBankAccount = async data => {
    return await http.put("/bank-account", data);
};

export const GetBankAccount = async() => {
    return await http.get("/bank-account");
};

export const DeleteBankAccount = async id => {
    return await http.delete("/bank-account/" + id);
};