import { 
    AddItem, 
    GetItems, 
    UpdateItem, 
    DeleteItem,
} from "@/services/item";


export const getItems = (context, params) => {
    context.commit("updateLoader", { getItem: true });
    return new Promise((resolve, reject) => {
        GetItems(params)
            .then(res => {
                context.commit("getItems", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getItem: false });
            });
    });
};

export const addItem = (context, data) => {
    context.commit("updateLoader", { addItem: true });
    return new Promise((resolve, reject) => {
        AddItem(data)
            .then(res => {
                context.commit("addItem", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { addItem: false });
            });
    });
};

export const updateItem = (context, data) => {
    context.commit("updateLoader", { updateItem: true });
    const id = data.id;
    delete data.id
    return new Promise((resolve, reject) => {
        UpdateItem(id, data)
            .then(res => {
                context.commit("updateItem", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { updateItem: false });
            });
    });
};

export const deleteItem = (context, data) => {
    context.commit("updateLoader", { deleteItem: true });
    return new Promise((resolve, reject) => {
        DeleteItem(data)
            .then(res => {
                context.commit("deleteItem", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { deleteItem: false });
            });
    });
};


const ItemAction = {
    getItems,
    addItem,
    updateItem,
    deleteItem
};

export default ItemAction;