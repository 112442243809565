export const loadTransactions = (state, transactions) => {
  state.transactions = transactions;
};


const TransactionMutation = {
  loadTransactions
};

export default TransactionMutation;
