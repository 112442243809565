<template>
  <div class="py-2">
    <nav class="block">
      <ul class="flex pl-0 rounded list-none flex-wrap">
        <li v-for="(page, i) in pages" :key="i" @click="selectPage(page)" class="ml-1">
          <a href="#" :class="`first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-emerald-500 ${activePage === page ? 'text-white bg-emerald-500' : 'bg-white text-emerald-500'} `">
            {{ page }}
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>

export default {
  props: ["current_page", "last_page", "size"],
  data() {
    return {
      pages: [1],
      activePage: 1
    };
  },
  mounted() {
    this.buildPages()
  },
  methods: {
    buildPages() {
      const pages = [1];
      this.activePage = this.current_page;

      if ( this.last_page === 1 ) {
        pages.push(2);
        this.pages = pages;
        return;
      }

      if ( this.current_page > 2 ) {
        const prev = this.current_page - 1;
        pages.push(prev);
      }

      if ( this.current_page !== 1 ) {
        pages.push(this.current_page)
      }

      if ( this.current_page + 1 < this.last_page ) {
        const next = this.current_page + 1;
        pages.push(next);
      }

      pages.push(this.last_page)

      // // pages in tenths
      // for ( let i = 10; i < this.last_page; i + 10 ) {
      //   pages.push(i)
      // }

      // // pages in hunderds
      // for ( let i = 100; i < this.last_page; i + 100 ) {
      //   pages.push(i)
      // }

      // // pages in thousands
      // for ( let i = 1000; i < this.last_page; i + 1000 ) {
      //   pages.push(i)
      // }

      // pages.sort(function(a, b){return a - b});

      this.pages = pages

    },

    selectPage(page) {
      this.activePage = page;
      const pagination = { page, size: this.size }
      this.$emit('paginate', pagination)
    }
  },
  watch: {
    // whenever question changes, this function will run
    current_page(last_page, old_last_page) {
      if ( old_last_page != last_page) {
        this.buildPages()
      }
    }
  },
};
</script>
