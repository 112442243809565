export const getBusinesses = (state, businesses) => {
    state.businesses = businesses;
};

export const getUserBusinesses = (state, businesses) => {
    state.businesses = businesses;
};

export const confirmBusiness = (state, business) => {
    state.business = business;
};


export const addBusiness = (state, business) => {
    state.businesses.data = [business, ...state.businesses.data];
};

export const updateBusiness = (state, business) => {
    const businessIndex = state.businesses.data.findIndex(
        (_business) => _business.id === business.id
    );
    if (businessIndex < 0) {
        return;
    }
    state.businesses.data[businessIndex] = business;
};

export const deleteBusiness = (state, business) => {
    const businesses = state.businesses.data.filter(
        (_business) => _business.id !== business.id
    );
    state.businesses.data = businesses;
};

export const addAppConfig = (state, config) => {
    state.appConfig = config;
};

export const updateAppConfig = (state, config) => {
    state.appConfig = config;
};

export const getCommission = (state, commissions) => {
    state.commissions = commissions;
};

export const actingAsBusiness = (state, business) => {
    state.actingAsBusiness = business
    localStorage.setItem('business', JSON.stringify(business))
}

export const stopActingAsBusiness = (state) => {
    state.actingAsBusiness = {}
    localStorage.removeItem('business')
}


const BusinessMutation = {
    getBusinesses,
    confirmBusiness,
    getUserBusinesses,
    addBusiness,
    updateBusiness,
    deleteBusiness,
    addAppConfig,
    updateAppConfig,
    getCommission,
    stopActingAsBusiness,
    actingAsBusiness
};

export default BusinessMutation;