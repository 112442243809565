import AccountState from "./account.state";
import LoaderState from "./loader.state";
import NotificationState from "./notification";
import TransactionState from "./transaction.state";
import UserState from "./user.state";
import QuestionState from "./question";
import PlanState from "./plan";
import PredictionState from "./prediction";
import SettingsState from "./settings.state";
import BankAccountState from "./bankAccount.state";
import ToastState from "./toast.state";
import CoinState from "./coin";
import MetricsState from "./metrics.state";
import CurrencyState from "./currency";
import RoleState from "./role";
import BusinessState from "./business.state";
import ItemState from "./item.state";

const states = {
    ...SettingsState,
    ...TransactionState,
    ...UserState,
    ...PlanState,
    ...LoaderState,
    ...NotificationState,
    ...AccountState,
    ...QuestionState,
    ...PredictionState,
    ...BankAccountState,
    ...ToastState,
    ...CoinState,
    ...MetricsState,
    ...CurrencyState,
    ...RoleState,
    ...BusinessState,
    ...ItemState
};

export default states;