<template>
  <div>
    <plan-stats @statChange="changeParam"></plan-stats>
    <div class="flex flex-wrap">
      <div class="w-full">
        
        <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
          <div class="px-4 py-5 flex-auto">
            <div class="tab-content tab-space">
              <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}" class="flex flex-wrap">
                <div class="w-full px-4" :class="{'lg:w-8/12': Object.values(planUserModel).length > 0}">
                  <card-user-plan-list :plans="userPlan()" :param="param" @selectedPlan="changePlanUser"></card-user-plan-list>
                </div>
                <transition name="bounce">
                  <div class="w-full lg:w-4/12 px-4" v-if="Object.values(planUserModel).length > 0">
                    <card-user-plan :selectedPlan="planUserModel" @close="handleCloseUserPlan" ></card-user-plan>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardUserPlan from "@/components/Cards/CardUserPlan.vue";
import CardUserPlanList from "@/components/Cards/CardUserPlanList.vue";
import PlanStats from "@/components/Stats/PlanStats.vue";
import { mapActions, mapGetters } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export default {
  components: {
    PlanStats,
    CardUserPlan,
    CardUserPlanList,
  },
  data() {
    return {
      plan: {},
      planUserModel: {},
      openTab: 1,
      param: {}
    }
  },
  mounted() {
    // this.getAllPlans();
    this.fetchPlanUser()
  },
  methods: {
    ...mapActions([
      // "getAllPlans", 
      "fetchPlanUser"
    ]),
    ...mapGetters([
      "plans", 
      "userPlan"
    ]),
    changePlan(plan) {
      this.plan = plan
    },
    changePlanUser(planUser) {
      this.planUserModel = planUser
    },
    changeParam(param) {
      this.param = param
      
      this.fetchPlanUser(param).then( response => {
        if ( param.get_csv ) {
          // create file link in browser's memory
          const href = window.URL.createObjectURL(response);
          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download','plans.csv'); //or any other extension
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);

          toaster.success("Report downloaded Successfully");
        }
      })
    },
    toggleTabs: function(tabNumber){
      this.openTab = tabNumber
    },
    handleCloseUserPlan(){
      this.planUserModel = {}
    }
  }
};
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
