<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-emerald-800 text-xl font-bold" style="color:#065f46">Profile</h6>
        <!-- <button
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
        >
          Settings
        </button> -->
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          <i class="fas fa-user mr-1 text-sm text-emerald-600"></i> User Information
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                First Name :
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                v-model="v$.profile_obj.first_name.$model"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Last Name :
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                v-model="v$.profile_obj.last_name.$model"
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Date Of Birth :
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                v-model="v$.profile_obj.dob.$model"
                id="date-of-birth"
              />
            </div>
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          <i class="fas fa-address-book mr-1 text-sm text-emerald-600"></i> Contact Information
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Address :
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                v-model="v$.profile_obj.address.$model"
              />
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                City :
              </label>
              <input
                type="email"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                v-model="v$.profile_obj.city.$model"
              />
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Country :
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                v-model="v$.profile_obj.country.$model"
              />
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Postal Code :
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                v-model="v$.profile_obj.postal_code.$model"
              />
            </div>
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          <i class="fas fa-comment mr-1 text-sm text-emerald-600"></i> About Me
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-1">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                About me :
              </label>
              <textarea
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                rows="4"
                v-model="v$.profile_obj.about.$model"
              ></textarea
              >
            </div>
          </div>
        </div>

        <div class="relative w-full mb-3 px-4 mt-3">
          <button
            class="bg-emerald-500 flex text-center justify-center text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
            type="button" 
            @click="updateProfile" 
            :disabled="loaders().saveProfile"
          >
            <span v-if="loaders().saveProfile" class="flex loader loader-sm"></span><span v-else>Save</span>
          </button>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import useVuelidate from '@vuelidate/core';
import datepicker from 'js-datepicker'
// import { required, decimal, numeric, minLength, maxLength } from '@vuelidate/validators';

export default {
  props: ["profile"],
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      profile_obj: {
        first_name: { require },
        last_name: { require },
        address: {},
        city: {},
        country: {},
        postal_code: {},
        about: {},
        dob: { require }
      }
    }
  },
  mounted() {
    const self = this;
    setTimeout( function() {
      self.start_date = datepicker("#date-of-birth", {
        id: "date-of-birth",
        onSelect: (_, dateInstance) => {
          const date = new Date(dateInstance);
          const day = date.getDate();
          const month = date.getMonth() + 1;
          const year = date.getFullYear();
          self.profile_obj.dob = `${year}-${month}-${day}`;
        },
        // disabler: date => moment(new Date).isBefore(moment(date))
      });
    }, 1000)
  },
  data() {
    return {
      profile_obj: {}
    }
  },
  watch: {
    '$store.state.userProfile': function(n) {
      this.profile_obj = n
    }
  },
  methods: {
    ...mapActions(["saveProfile"]),
    ...mapGetters(["loaders", "userProfile", "auth"]),
    updateProfile: function(){
      this.saveProfile(this.profile_obj)
    },
  }
};
</script>