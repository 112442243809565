<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3
            class="font-semibold text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
          >
            User
          </h3>
        </div>

        <div class="flex w-10/12 flex-wrap justify-end items-center">
          <div class="flex flex-wrap w-full lg:w-6/12 mb-3 align-middle items-center">
            <div>Search: &nbsp;</div>
            <div class="relative w-2/3">
              <input 
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                @keyup="searchUser" 
                v-model="search"
                :disabled="loading"
              />
            </div>
            <div class="relative w-2/5 ml-1">
              <select v-model="pageSize" :disabled="loading" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                <option v-for='(cur, i) in [10, 15, 20, 50, 10]' :key="i" >{{ cur }}</option>
              </select>
            </div>
          </div>

          <div class=" px-4">
            <div class="relative w-full mb-3">
              <button
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
                type="button" 
                :disabled="loading"
                @click="get_csv=true;fetchNewUserPage()"
              >
                Download
              </button>| 
              <button
                v-if="has('user.register.create')"
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
                type="button" 
                :disabled="loading"
                @click="toggleModal('createUser', true)"
              >
                Create User
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Name
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Email
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Phone
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emeblockUserrald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Verified
            </th>
            
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Naira Balance <br />
              <small>
                (Ledger/Balance)
              </small>
            </th>
            <!-- <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              USD Balance <br/>
              <small>
                (Ledger/Balance)
              </small>
            </th> -->
            <!-- <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              USDT Balance<br/>
              <small>
                (Ledger/Balance)
              </small>
            </th> -->
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Registration
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, i) in users().data" :key="i">
            <th
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center"
            >
              <img
                :src="bootstrap"
                class="h-12 w-12 bg-white rounded-full border"
                alt="..."
              />
              <span
                class="ml-3 font-bold"
                :class="[
                  color === 'light' ? 'text-blueGray-600' : 'text-white',
                  user.is_blocked ? 'line-through italic' : ''
                ]"
              >
                {{ user.name }}
              </span>
            </th>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
            <span :class="[
              user.is_blocked ? 'line-through italic' : ''
            ]">
              {{ user.email }}
            </span>
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {{ user.profile?.phone }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <i :class="`fas fa-circle ${user.email_verified_at ? 'text-emerald-600' :  'text-orange-500 '}  mr-2`"></i> {{ user.email_verified_at ? 'yes' : 'no' }}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div class="flex items-center">
                <span class="mr-2">
                  {{ user.naira_wallet?.base_currency }}
                  {{ user.naira_wallet?.ledger_balance.toFixed(2) }} / 
                  {{ user.naira_wallet?.base_currency }}
                  {{ user.naira_wallet?.balance.toFixed(2) }}
                </span>
              </div>
            </td>
            <!-- <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div class="flex items-center">
                <span class="mr-2">
                  {{ user.usd_wallet?.base_currency }}
                  {{ user.usd_wallet?.ledger_balance }} / 
                  {{ user.usd_wallet?.base_currency }}
                  {{ user.usd_wallet?.balance }}
                </span>
              </div>
            </td> -->
            <!-- <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div class="flex items-center">
                <span class="mr-2">
                  {{ user.usdt_wallet?.base_currency }}
                  {{ user.usdt_wallet?.ledger_balance }} / 
                  {{ user.usdt_wallet?.base_currency }}
                  {{ user.usdt_wallet?.balance }}
                </span>
              </div>
            </td> -->
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div class="flex items-center">
                <span class="mr-2">
                  {{ moment(user.created_at).format("DD MMM YY hh:mm") }}
                </span>
              </div>
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
            >
              <table-dropdown 
                :user="user" 
                @add-saving-plan-click="(e) => {setUser(user); toggleModal('addToSavingsPlan', true)}" 
                @view-info-click="(e) => {setUser(user); toggleModal('userInformation', true)}"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex flex-wrap items-center">
      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
        <circular-pagination :current_page="users().current_page" :last_page="users().last_page" :size="pageSize" @paginate="pageChange" ></circular-pagination>
      </div>
    </div>

    <div v-if="modals.createUser" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
      <div class="relative w-auto my-6 mx-auto">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-xl font-semibold">
              Create User
            </h3>
            <button 
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
              v-on:click="toggleModal('createUser', false)"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <form>
              <div v-if="stage === stages.OTP_FORM">
                <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Validation
                </h6>
                <div class="flex flex-wrap">
                  <div class="w-full px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        OTP
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                        v-model="v$.otp.$model"
                      />
                      <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.otp.$errors" :key="index">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="relative w-full mb-3 px-4 mt-8" v-if="has('user.proxy-create.ability')">
                  <button
                    class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
                    type="button"
                    @click="validateAndRegister" 
                    :disabled="loaders().validateCode || loaders().registerUserProxy || v$.otp.$invalid" 
                  >
                    Validate & Create
                  </button>
                </div>
              </div>
              <div v-else-if="stage === stages.COMPLETED">
                <div class="flex justify-center items-center h-8">
                  User Added Successfully.
                </div>
              </div>
              <div v-else>
                <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  User Information
                </h6>
                <div class="flex flex-wrap">
                  <div class="w-full px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        name
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                        v-model="v$.user.name.$model"
                      />
                      <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.user.name.$errors" :key="index">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Phone 
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                        v-model="v$.user.phone.$model"
                      />
                      <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.user.phone.$errors" :key="index">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Email 
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                        v-model="v$.user.email.$model"
                      />
                      <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.user.email.$errors" :key="index">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="relative w-full mb-3 px-4 mt-8" v-if="has('user.init.ability')">
                  <button
                    class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
                    type="button"
                    @click="validateAndSendCode" 
                    :disabled="loaders().registerUserInit || v$.user.$invalid" 
                  >
                    Continue
                  </button>
                </div>
              </div>

              <div class="input-errors text-xs text-danger mt-2" v-for="([key, error], index) of Object.entries(errors)" :key="index">
                <div class="error-msg">{{ key }}: {{ error }}</div>
              </div>

            </form>

          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">
            <button 
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
              v-on:click="toggleModal('createUser', false)"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="modals.createUser" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-40 h-screen top-0 left-0 bg-black"></div>

    <div v-if="modals.addToSavingsPlan" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
      <div class="relative w-auto my-6 mx-auto">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-xl font-semibold">
              Add to Savings
            </h3>
            <button 
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
              v-on:click="toggleModal('addToSavingsPlan', false)"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <form>
              <div v-if="stage === stages.PREVIEW">
                <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Confirm
                </h6>
                <div class="flex flex-wrap">
                  <div class="w-full px-4">
                    Confirm you want to add {{ user.name }} to {{ planUser.name }} savings plan.
                  </div>
                </div>
                <div class="relative w-full mb-3 px-4 mt-8">
                  <button
                    class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
                    type="button"
                    @click="joinSubPlan" 
                    :disabled="loaders().joinPlan" 
                  >
                    Add User
                  </button>
                </div>
              </div>
              <div v-else-if="stage === stages.COMPLETED">
                <div class="flex justify-center items-center h-8">
                  User Added Successfully.
                </div>
              </div>
              <div v-else>
                <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                  Search Plan
                </h6>
                <div class="flex flex-wrap">
                  <div class="w-full px-4">
                    <div class="relative w-full mb-3">
                      <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Code
                      </label>
                      <input
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                        v-model="v$.savingsPlanCode.$model"
                      />
                      <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.savingsPlanCode.$errors" :key="index">
                        <div class="error-msg">{{ error.$message }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="relative w-full mb-3 px-4 mt-8">
                  <button
                    class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
                    type="button"
                    @click="findPlan" 
                    :disabled="loaders().fetchByCode || v$.savingsPlanCode.$invalid" 
                  >
                    Find Plan
                  </button>
                </div>
              </div>

              <div class="input-errors text-xs text-danger mt-2" v-for="([key, error], index) of Object.entries(errors)" :key="index">
                <div class="error-msg">{{ key }}: {{ error }}</div>
              </div>

            </form>

          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">
            <button 
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
              v-on:click="toggleModal('addToSavingsPlan', false)"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="modals.addToSavingsPlan" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-40 h-screen top-0 left-0 bg-black"></div>

    <div v-if="modals.userInformation" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
      <div class="relative w-auto my-6 mx-auto">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-xl font-semibold">
              User Information
            </h3>
            <button 
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
              v-on:click="toggleModal('userInformation', false)"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <table>
              <tbody>
                <tr
                v-for="([k, v], i) in Object.entries(user)" :key="i"
                class="border-b-1 border-t-1"
                >
                  <template v-if="!['id', 'is_password_auto_gen', 'usd_wallet', 'usdt_wallet', 'btc_wallet', 'eth_wallet'].includes(k)">
                    <td
                    class="border-t-1 border-b-1 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-1 text-left"
                    >{{ k.split("_").join(" ") }}</td> 
                    <td class="border-t-1 border-b-1 px-6 align-middle border-l-0 border-r-0 text-xs text-left flex items-center">
                      <template v-if="(typeof v === 'object') && v !== null">
                        <hr/>
                        <table class="border-t-1 py-2">
                          <tbody>
                            <tr
                            v-for="([a, b], j) in Object.entries(v)" :key="j"
                            >
                              <template v-if="!['id', 'user_id', 'image', 'lien', 'is_frozen', 'deleted_at'].includes(a)">
                                <td>{{ a.split("_").join(" ") }}</td>
                                <td>{{ b }}</td>
                              </template>
                            </tr>
                          </tbody>
                        </table>
                      </template>
                      <template v-else>
                        {{ v }}
                      </template>
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">
            <button 
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
              v-on:click="toggleModal('userInformation', false)"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="modals.userInformation" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-40 h-screen top-0 left-0 bg-black"></div>
  </div>
</template>
<script>
import TableDropdown from "@/components/Dropdowns/UserTableDropdown.vue";
import CircularPagination from "@/components/Paginations/CircularPagination.vue";
import useVuelidate from '@vuelidate/core';
import { required, minLength, maxLength } from '@vuelidate/validators';
import bootstrap from "@/assets/img/bootstrap.jpg";
import { mapActions, mapGetters } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
import moment from "moment"

const toaster = createToaster({ /* options */ });

export default {
  data() {
    return {
      bootstrap,
      pageSize: 10,
      timer: null,
      search: "",
      loading: false,
      get_csv: false,
      pagination:{},
      modals: {},
      user: {},
      stages: {
        CREATE_FORM: 'create-form',
        OTP_FORM: 'otp-form',
        PREVIEW: 'preview',
        COMPLETED: 'completed'
      },
      stage: "",
      errors: {},
      otp: "",
      savingsPlanCode: "",
      planUser: null
    };
  },
  components: {
    TableDropdown,
    CircularPagination
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      user: {
        name: { required },
        phone: { required },
        email: { required }
      },
      otp: { required, min: minLength(6), max: maxLength(7) },
      savingsPlanCode: { required, min: minLength(6), max: maxLength(7) },
    }
  },
  created() {
    this.moment = moment
  },
  methods: {
    ...mapActions(["getAllUsers", "logout", "registerUserInit", "fetchByCode", "joinPlan"]),
    ...mapGetters(["users", "loaders", "auth", "dashboard"]),
    pageChange(pagination) {
      this.pagination = pagination;
      this.fetchNewUserPage();
    },
    fetchNewUserPage() {
      const params = {
        ...this.pagination,
        get_csv: this.get_csv,
        search: this.search
      }
      const self = this
      this.loading = true
      this.getAllUsers(params).then( response => {
        if ( self.get_csv ) {
          // create file link in browser's memory
          const href = window.URL.createObjectURL(response);
          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download','users.csv'); //or any other extension
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);

          toaster.success("Report downloaded Successfully");
        }
      }).finally(() => {
        self.get_csv = false
        self.loading = false
      })
    },
    searchUser() {
      const self = this
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        self.fetchNewUserPage()
      }, 1000)
    },
    logoutUser: function() {
      this.logout().then(() => {
        const self = this;
        setTimeout( function(){
          self.$router.push('/auth');
        }, 900);
      }).catch( err => {
        console.log(err)
      })
    },
    toggleModal(modalName, isvisible) {
      this.setStage("");
      this.modals[modalName] = isvisible
    },
    validateAndSendCode() {
      const self = this
      this.registerUserInit(this.user).then( r => {
          console.log(r)
          self.setStage(self.stages.OTP_FORM)
      }).catch( e => {
          const { data } = e
          this.errors = data
      })
    },
    validateAndRegister() {
      if ( this.code.length !== 6 ) {
          return;
      }
      const self = this
      this.validateCode({
          email: this.user.email,
          code: this.code
      }).then( r => {
          console.log(r)
          const { settings, cluster } = self.auth()
          const { business } = self.dashboard()
          self.registerUserProxy({
              ...self.user,
              // referrer: settings.referrer_code,
              business: business ? business.uuid : cluster.uuid
          }).then( res => {
            console.log(res)
            self.setStage(self.stages.COMPLETED)
          }).catch( err => {
            console.log(err, settings)
          })
      }).catch( e => {
          console.log(e)
      })
    },
    setStage(stage) {
      this.errors = {}
      this.stage = stage
    },
    setUser(user) {
      this.user = user;
    },
    findPlan() {
      if ( !this.savingsPlanCode ) {
        this.errors = {message: "You cannot subscribe a user to this plan"}
        return
      }
      const self = this;
      this.fetchByCode(this.savingsPlanCode).then (r => {
        toaster.success("Kindly confirm plan " + r.name + ".")
        self.setStage(self.stages.PREVIEW)
        self.planUser = r;
      }).catch( e => {
        const { message } = e
        self.errors = { message }
      });
    },
    joinSubPlan() {
      if ( !this.savingsPlanCode ) {
        this.errors = {message: "You cannot subscribe a user to this plan"}
        return
      }
      const self = this
      this.joinPlan({user: this.user.id, code: this.savingsPlanCode}).then (r => {
        toaster.success("You have added " + self.user.name + " to plan " + r.name + ". Kindly reload.")
        self.setStage(self.stages.COMPLETED)
      }).catch( e => {
        const { message } = e
        this.errors = { message }
      });
    },
  },
  mounted() {
    this.getAllUsers().then( r => {
      const { message } = r
      const self = this
      if ( message === "Unauthenticated." ) {
        self.logoutUser()
      }
    }).catch( e => {
      const { message } = e
      const self = this
      if ( message === "Unauthenticated." ) {
        self.logoutUser()
      }
    })
  }
};
</script>
