<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700">
            Products
          </h3>
        </div>
        <div
          class="relative w-1/2 px-4 max-w-1/2 flex-grow flex-1 text-right flex"
        >
          <div class="w-1/2">
             <select @change="fetchNewUserPage('')" v-model="category" :disabled="loaders().getItems" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150">
              <option v-for='(cur, i) in transactionList' :key="i" >{{ cur }}</option>
            </select>
          </div>
          <div class="w-1/2">
            <button
              class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
              type="button" 
              :disabled="loading"
              @click="(e) => $emit('initNewProduct', e)"
            >
              New Product
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <div class="flex flex-wrap">
        <template v-if="items().data.length > 0">
          <div class="w-full lg:w-4/12 px-4" v-for="(item, i) in items().data" :key="i">
            <div class="items-center my-4 flex items-start flex-col shadow-lg rounded-lg min-w-0 break-words">
              <img :src="placeholder" class="mx-4 w-20 p-2" alt="..." v-if="!item.thumbnails">
              <span v-else class="flex w-full overflow-scroll">
                <img v-for="(thumbnail, j) in item.thumbnails" :key="j" :src="thumbnail" class="w-20 p-2" alt="...">
              </span>
              <div class="w-full px-4 pb-4">
                <div class="flex-1">
                  <div class="flex justify-between">
                    <span>
                      <h6 class="text-xl font-semibold leading-tight mb-1">{{ item.name }}</h6>
                      <span class="text-blueGray-700">{{ formatter("NGN").format(item.price) }}</span>
                    </span>
                    <span>
                      <p class="text-blueGray-500 uppercase font-bold text-xs">units: {{ item.quantity }}</p>
                      <span class="text-blueGray-500" @click="$emit('selectedProduct', item)">
                        <i class="fa fa-pen text-xs"></i> edit
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="loaders().getItems">
          <div class="flex items-center justify-center w-full my-6">
            <span class="loader block"></span>
          </div>
        </template>
        <template v-else>
          <div class="flex items-center justify-center w-full my-6">
            <i class="fa fa-empty"></i> No item present
          </div>
        </template>
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { transactionList } from '../../utils/constant.util'
import { formatter } from '../../utils/formatter.util'
import placeholder from "@/assets/img/landscape-placeholder.svg";

export default {
  // props: ["data"],
  data() {
    return {
      category: "",
      transactionList,
      modals: {},
      transaction: {},
      placeholder
    }
  },
  methods: {
    ...mapActions(["getItems"]),
    ...mapGetters(["items", "loaders"]),
    fetchNewUserPage(q) {
      this.getItems({category: this.category, q})
    },
    toggleModal(modalName, isvisible) {
      this.modals[modalName] = isvisible
    }
  },
  mounted() {
    this.getItems()
  },
  created() {
    this.formatter = formatter
  }
}
</script>
