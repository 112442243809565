<template>
  <div class="flex flex-wrap">
    <div class="w-full">
      <div class="relative flex flex-col min-w-0 break-words  w-full shadow-lg rounded">
        <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
          <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(1)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 1, 'text-white bg-orange-500': openTab === 1}">
              Products
            </a>
          </li>
          <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(2)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 2, 'text-white bg-orange-500': openTab === 2}">
              Orders
            </a>
          </li>
          <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(3)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 3, 'text-white bg-orange-500': openTab === 3}">
              Carts
            </a>
          </li>
          <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(4)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 4, 'text-white bg-orange-500': openTab === 4}">
              WishLists
            </a>
          </li>
        </ul>
      </div>
      <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div class="px-4 py-5 flex-auto">
          <div class="tab-content tab-space">
            <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}" class="flex flex-wrap">
              <div 
                class="w-full px-4" :class="{'lg:w-12/12': product === null, 'lg:w-8/12': product !== null}">
                <card-product-lists @init-new-product="handleInitNewProduct" @selectedProduct="(p) => product = p"/>
              </div>
              <transition name="bounce">
                <div class="w-full lg:w-4/12 px-4" v-if="product !== null" >
                  <card-product @close="handleCloseProduct" :selectedProduct="product"></card-product>
                </div>
              </transition>
            </div>

            <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}" class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                hello
              </div>
            </div>

            <div v-bind:class="{'hidden': openTab !== 3, 'block': openTab === 3}" class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                hello
              </div>
            </div>

            <div v-bind:class="{'hidden': openTab !== 4, 'block': openTab === 4}" class="flex flex-wrap">
              <div class="w-full lg:w-12/12 px-4">
                hello
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import CardProductLists from "@/components/Cards/CardProductLists.vue";
import CardProduct from "@/components/Cards/CardProduct.vue";

export default {
  components: {
    CardProductLists,
    CardProduct
  },
  data() {
    return {
      openTab: 1,
      product: null
    }
  },
  methods: {
    toggleTabs: function(tabNumber) {
      this.openTab = tabNumber
    },
    changeProduct: function(product) {
      this.product = product
    },
    handleCloseProduct: function() {
      this.product = null
    },
    handleInitNewProduct: function() {
      this.product = {}
    }
  }
};
</script>
