const BusinessState = {
    businesses: {
        data: []
    },
    business: {},
    appConfig: {},
    commissions: {
        data: []
    },
    commission: {},
    actingAsBusiness: {},
};

export default BusinessState;