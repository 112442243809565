<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style>
html, body {
    /* font-family: "Open Sans", sans-serif; */
    font-family: "inter", sans-serif;
    font-weight: 100;
    font-style: normal;
    font-variation-settings: "wdth" 100;
}
</style>