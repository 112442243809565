<template>
  <div>
    <a
      class="text-blueGray-500 py-1 px-3"
      href="#pablo"
      ref="btnDropdownRef"
      v-on:click="toggleDropdown($event)"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
      ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <a
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        @click="updateLimit()"
        v-if="has('user.block.create') || has('user.unblock.create')"
      >
        {{ isBlocked ? 'un-block': 'block' }}
      </a>
      <a
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        @click="(e) => $emit('add-saving-plan-click', e)"
      >
        Add to Savings Plan
      </a>
      <a
        href="javascript:void(0);"
        class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        @click="(e) => $emit('view-info-click', e)"
      >
        View Info
      </a>
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";
import { mapActions, mapGetters } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export default {
  props: ["user"],
  data() {
    return {
      dropdownPopoverShow: false,
      isBlocked: false
    };
  },
  methods: {
    ...mapActions(["unBlockUser", "blockUser"]),
    ...mapGetters([]),
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    updateLimit: function() {
      if ( this.isBlocked && !this.has('user.unblock.create')) {
        toaster.show("you do not have the permission to un-block users")
        return
      } else if ( !this.isBlocked && !this.has('user.block.create')) {
        toaster.show("you do not have the permission to block users")
        return
      }
      const q = this.isBlocked ? this.unBlockUser(this.user.id) : this.blockUser(this.user.id)
      const self = this;
      q.then( res => {
        console.log(res)
        self.isBlocked = !self.isBlocked
      });
    }
  },
  mounted() {
    this.isBlocked = this.user.is_blocked
  }
};
</script>
