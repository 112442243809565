<template>
  <!-- Header -->
  <div class="relative bg-blueGray-100 text-emerald-800 pb-4">
    <div class="mx-auto w-full" v-if="has('plan.summary.view')">
      <div>
        <!-- Card stats -->
        <div class="flex flex-wrap" v-if="balances()">
          <div v-for="([currency, stat], i) in Object.entries(balances())" :key="i" class="w-full lg:w-6/12 xl:w-3/12 px-4 mt-2">
            <card-stats
              :statSubtitle="`${currency} balance/Ledger`"
              :statTitle="`${formatAbbrCurrency({
                currency,
                amount:stat[0].total_balance || 0
                })}/${formatAbbrCurrency({
                  currency,
                  amount: stat[0].total_ledger_balance || 0
                })}`"
              :statArrow="`${'up'}`"
              :statCount="`${stat[0].count}`"
              :statPercentColor="`text-orange-500`"
              :statDescripiron="`count`"
              statIconName="far fa-chart-bar"
              statIconColor="bg-red-500"
            />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import CardStats from "@/components/Cards/CardStats.vue";
import { mapActions, mapGetters } from 'vuex';
import { formatAbbrCurrency } from "../../utils/formatter.util"

export default {
  components: {
    CardStats
  },
  created() { 
    this.formatAbbrCurrency = formatAbbrCurrency;
  },
  mounted() {
    this.getBalances({})
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapActions(["getBalances"]),
    ...mapGetters(["balances"]),
    // Create our number formatter.
    formatter() {
      return new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
    },
  }
};
</script>
