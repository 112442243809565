import { 
    GetUserBusinesses, 
    ConfirmBusiness, 
    AddBusiness,
    GetBusinesses,
    UpdateBusiness,
    DeleteBusiness,
    AddAppConfig,
    UpdateAppConfig,
    CheckSubdomain,
    GetCommission,
    ReGenerateKey
} from "@/services/business";


export const getBusinesses = (context) => {
    context.commit("updateLoader", { getBusinesses: true });
    return new Promise((resolve, reject) => {
        GetBusinesses()
            .then(res => {
                context.commit("getBusinesses", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getBusinesses: false });
            });
    });
};

export const confirmBusiness = (context, data) => {
    context.commit("updateLoader", { confirmBusiness: true });
    return new Promise((resolve, reject) => {
        ConfirmBusiness(data)
            .then(res => {
                // context.commit("confirmBusiness", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { confirmBusiness: false });
            });
    });
};

export const getUserBusinesses = (context) => {
    context.commit("updateLoader", { getUserBusinesses: true });
    return new Promise((resolve, reject) => {
        GetUserBusinesses()
            .then(res => {
                context.commit("getUserBusinesses", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getUserBusinesses: false });
            });
    });
};

export const addBusiness = (context, data) => {
    context.commit("updateLoader", { addBusiness: true });
    return new Promise((resolve, reject) => {
        AddBusiness(data)
            .then(res => {
                context.commit("addBusiness", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { addBusiness: false });
            });
    });
};

export const updateBusiness = (context, data) => {
    context.commit("updateLoader", { updateBusiness: true });
    const id = data.id;
    delete data.id
    return new Promise((resolve, reject) => {
        UpdateBusiness(id, data)
            .then(res => {
                context.commit("updateBusiness", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { updateBusiness: false });
            });
    });
};

export const deleteBusiness = (context, data) => {
    context.commit("updateLoader", { deleteBusiness: true });
    return new Promise((resolve, reject) => {
        DeleteBusiness(data)
            .then(res => {
                context.commit("deleteBusiness", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { deleteBusiness: false });
            });
    });
};

export const addAppConfig = (context, data) => {
    context.commit("updateLoader", { addAppConfig: true });
    return new Promise((resolve, reject) => {
        AddAppConfig(data)
            .then(res => {
                context.commit("addAppConfig", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { addAppConfig: false });
            });
    });
};

export const updateAppConfig = (context, data) => {
    context.commit("updateLoader", { updateAppConfig: true });
    const id = data.id;
    delete data.id
    return new Promise((resolve, reject) => {
        UpdateAppConfig(id, data)
            .then(res => {
                context.commit("updateAppConfig", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { updateAppConfig: false });
            });
    });
};

export const checkSubdomain = (context, data) => {
    context.commit("updateLoader", { checkSubdomain: true });
    return new Promise((resolve, reject) => {
        CheckSubdomain(data)
            .then(res => {
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { checkSubdomain: false });
            });
    });
};

export const getCommission = (context, data) => {
    context.commit("updateLoader", { getCommission: true });
    return new Promise((resolve, reject) => {
        GetCommission(data)
            .then(res => {
                context.commit("getCommission", res.data.data);
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { getCommission: false });
            });
    });
};

export const reGenerateKey = (context, data) => {
    const {business_id, path, password} = data
    context.commit("updateLoader", { reGenerateKey: true });
    return new Promise((resolve, reject) => {
        ReGenerateKey({password}, business_id, path)
            .then(res => {
                resolve(res.data.data);
            })
            .catch(err => {
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { reGenerateKey: false });
            });
    });
};

const BusinessAction = {
    getBusinesses,
    confirmBusiness,
    getUserBusinesses,
    addBusiness,
    updateBusiness,
    deleteBusiness,
    addAppConfig,
    updateAppConfig,
    checkSubdomain,
    getCommission,
    reGenerateKey
};

export default BusinessAction;