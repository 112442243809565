import { GetTransactions, GetMyTransactions } from "../../services/transaction";

export const getTransactions = async (context, data) => {
    context.commit("updateLoader", { fetchTransactions: true });

    return new Promise((resolve, reject) => {
        GetTransactions(data)
            .then(res => {
                if (data && data.get_csv) {
                    resolve(res.data);
                } else {
                    context.commit("loadTransactions", res.data.data);
                    resolve(res.data.data);
                }
            })
            .catch(err => {
                console.log(err);
                reject(err);
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchTransactions: false });
            });
        });
};

export const getMyTransactions = (context, data) => {
    context.commit("updateLoader", { fetchTransactions: true });
    return new Promise((resolve, reject) => {
        GetMyTransactions(data)
        .then(res => {
            if (data && data.get_csv) {
                resolve(res.data);
            } else {
                context.commit("loadTransactions", res.data.data);
                resolve(res.data.data);
            }
        })
        .catch(err => {
            console.log(err);
            reject(err);
        })
        .finally(() => {
            // stop loaders
            context.commit("updateLoader", { fetchTransactions: false });
        });
    });
  };

const TransactionAction = {
    getTransactions,
    getMyTransactions
};

export default TransactionAction;