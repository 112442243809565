export const getItems = (state, items) => {
    state.items = items;
};

export const addItem = (state, item) => {
    state.items.data = [item, ...state.items.data];
};

export const updateItem = (state, item) => {
    const itemIdx = state.items.data.findIndex(
        (_item) => _item.id === item.id
    );
    if (itemIdx < 0) {
        return;
    }
    state.items.data[itemIdx] = item;
};

export const deleteItem = (state, item) => {
    const items = state.items.data.filter(
        (_item) => _item.id !== item.id
    );
    state.items.data = items;
};

const ItemMutation = {
    getItems,
    addItem,
    updateItem,
    deleteItem
};

export default ItemMutation;