<template>
  <div>
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <div v-if="actingAsBusiness()" class="w-full text-gray-500 bg-gray-100 px-4 py-2 flex justify-between z-50 fixed top-0 right-0 left-0">
        Acting as {{ actingAsBusiness().name }}
        <button 
          class="bg-red-500 text-white active:bg-red-600 uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          @click="stopActingAsBusiness()"
          >
          Cancel
        </button>
      </div>
      <admin-navbar />
      <header-stats />
      <div class="px-4 md:px-10 mx-auto w-full -m-24">
        <router-view />
        <footer-admin />
      </div>
    </div>
  </div>
</template>
<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import HeaderStats from "@/components/Stats/HeaderStats.vue";
import FooterAdmin from "@/components/Footers/FooterAdmin.vue";
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  name: "admin-layout",
  components: {
    AdminNavbar,
    Sidebar,
    HeaderStats,
    FooterAdmin,
  },
  methods: {
    ...mapActions([
      "getAuthUser",
      "logout"
    ]),
    ...mapGetters([
      "auth",
      "actingAsBusiness"
    ]),
    ...mapMutations([
      "stopActingAsBusiness"
    ])
  },
  created() {
    if ( Object.keys(this.auth()).length === 0 ) {
      const self = this;
      this.getAuthUser().then( res => {
        const { message } = res;
        if (message === "Unauthenticated.") {
          self.logout()
          setTimeout( function(){
            self.$router.push('/login');
          }, 900);
        }
      }).catch((e) => {
        const { message } = e;
        if (message === "Unauthenticated.") {
          self.logout()
          setTimeout( function(){
            self.$router.push('/login');
          }, 900);
        }
      })
    }
  }
};
</script>
