<template>
  <div class="flex flex-wrap">
    <div class="w-full">
      <div class="relative flex flex-col min-w-0 break-words  w-full shadow-lg rounded">
        <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
          <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(1)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 1, 'text-white bg-orange-500': openTab === 1}">
              Profile
            </a>
          </li>
          <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(2)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 2, 'text-white bg-orange-500': openTab === 2}">
              Compliance
            </a>
          </li>
          <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(3)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 3, 'text-white bg-orange-500': openTab === 3}">
              API & Config
            </a>
          </li>
          <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(4)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 4, 'text-white bg-orange-500': openTab === 4}">
              Support
            </a>
          </li>
        </ul>
      </div>
      <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div class="px-4 py-5 flex-auto">
          <div class="tab-content tab-space">
            <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}" class="flex flex-wrap">
              <div class="w-full lg:w-8/12 px-4">
                <card-profile-form />
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <card-profile />
              </div>
            </div>
            <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}" class="flex flex-wrap">
              <div class="w-full lg:w-8/12 px-4">
                <card-compliance-form :activeBusiness="activeBusiness" :activeNextOfKin="activeNextOfKin" />
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <card-compliance @selectedBusiness="changeActiveBusiness" @selectedNextOfKin="changeActiveNextOfKin" />
              </div>
            </div>
            <div v-bind:class="{'hidden': openTab !== 3, 'block': openTab === 3}" class="flex flex-wrap">
              <div class="w-full lg:w-8/12 px-4">
                <card-api-form />
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <card-api />
              </div>
            </div>
            <div v-bind:class="{'hidden': openTab !== 4, 'block': openTab === 4}" class="flex flex-wrap">
              <card-contact />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardProfileForm from "@/components/Cards/CardProfileForm.vue";
import CardProfile from "@/components/Cards/CardProfile.vue";
import CardApiForm from "@/components/Cards/CardApiForm.vue";
import CardCompliance from "@/components/Cards/CardCompliance.vue";
import CardComplianceForm from "@/components/Cards/CardComplianceForm.vue";
import CardApi from "@/components/Cards/CardApi.vue";
import CardContact from "@/components/Cards/CardContact.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    CardComplianceForm,
    CardCompliance,
    CardProfileForm,
    CardProfile,
    CardApiForm,
    CardApi,
    CardContact
  },
  data() {
    return {
      openTab: 1,
      activeBusiness: {},
      activeNextOfKin: {}
    }
  },
  mounted(){
    this.getProfile()//.then(r => console.log(r))
    this.getUserBusinesses();
    this.getNextOfKins();
  },
  methods: {
    ...mapActions(["getProfile", "getUserBusinesses", "getNextOfKins"]),
    ...mapGetters(["loaders"]),
    toggleTabs: function(tabNumber){
      this.openTab = tabNumber;
    },
    changeActiveBusiness(business) {
      this.activeBusiness = business;
    },
    changeActiveNextOfKin(nextOfKin) {
      this.activeNextOfKin = nextOfKin;
    }
  }
};
</script>
