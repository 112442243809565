<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-emerald-800 text-xl font-bold" style="color:#065f46">Compliance</h6>
        <!-- <button
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
        >
          Documentation
        </button> -->
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          <i class="fas fa-book mr-1 text-sm text-emerald-600"></i> Business Details
        </h6>
        <div class="w-full lg:w-12/12 px-4" v-if="( businesses().data && businesses().data.length === 0) || Object.values(business).length > 0">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Business Name
            </label>
            <input
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
              v-model="v$.business.name.$model"
            />
          </div>

          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Business Address
            </label>
            <input
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
              v-model="v$.business.address.$model"
            />
          </div>

          <div class="flex flex-wrap">
            <div class="w-full lg:w-6/12 pr-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Lga :
                </label>
                <input
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  v-model="v$.business.lga.$model"
                />
              </div>
            </div>
            <div class="w-full lg:w-6/12 pl-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Country :
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  v-model="v$.business.country.$model"
                />
              </div>
            </div>
          </div>

          <div class="flex flex-wrap">
            <div class="w-full lg:w-6/12 pr-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  State :
                </label>
                <input
                  type="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  v-model="v$.business.state.$model"
                />
              </div>
            </div>
            <div class="w-full lg:w-6/12 pl-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Country :
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                  v-model="v$.business.country.$model"
                />
              </div>
            </div>
          </div>

          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Support Email :
            </label>
            <input
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
              v-model="v$.business.support_email.$model"
            />
          </div>

          <div class="relative w-full mb-3 mt-3">
            <button
              class="bg-emerald-500 flex text-center justify-center text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
              type="button" 
              :disabled="loaders().addBusiness || loaders().updateBusiness || v$.business.$invalid"
              @click="saveBusiness"
            >
              <span v-if="loaders().addBusiness || loaders().updateBusiness" class="flex loader loader-sm"></span><span v-else>Save Business</span>
            </button>
          </div>
        </div>
        <div v-else>Supplied <i class="fas fa-check text-lg text-emerald-600"></i></div>

        <hr class="mt-3 border-b-1 border-blueGray-300" />
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          <i class="fas fa-check-circle mr-1 text-sm text-emerald-600"></i> BVN Verification
        </h6>
        <div class="flex flex-wrap" v-if="!auth().meta?.is_verified">
          <div class="w-full lg:w-12/12 px-4">

            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                BVN
              </label>
              <!-- <input v-if="!Object.values(getDetails()).some( e => e)" 
                type="text"
                disabled
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                value="Complete your profile update to continue"
                placeholder="Complete your profile update to continue"
              /> -->
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
                v-model="v$.verification.bvn.$model"  
              />
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.verification.bvn.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="relative w-full mb-3">
              <button
                class="bg-emerald-500 flex text-center justify-center text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
                type="button" 
                :disabled="loaders().verifyAccount || v$.verification.bvn.$invalid"
                @click="verifyByBVN"
              >
                <span v-if="loaders().verifyAccount" class="flex loader loader-sm"></span><span v-else>Save</span>
              </button>
            </div>
          </div>
        </div>
        <div v-else>
          Verified <i class="fas fa-check text-lg text-emerald-600"></i>
        </div>

        <hr class="mt-3 border-b-1 border-blueGray-300" />
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          <i class="fas fa-user mr-1 text-sm text-emerald-600"></i> Next of Kin
        </h6>
        <div class="w-full lg:w-12/12 px-4" v-if="( nextOfKins().data && nextOfKins().data.length < 3 )|| Object.values(nextOfKin).length > 0">
          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Name :
            </label>
            <input
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
              v-model="v$.nextOfKin.name.$model"
            />
            <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.nextOfKin.name.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>

          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Phone :
            </label>
            <input
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
              v-model="v$.nextOfKin.phone.$model"
            />
            <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.nextOfKin.phone.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>

          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Email :
            </label>
            <input
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
              v-model="v$.nextOfKin.email.$model"
            />
            <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.nextOfKin.email.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>

          <div class="relative w-full mb-3">
            <label
              class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Relationship :
            </label>
            <input
              type="text"
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150"
              v-model="v$.nextOfKin.relationship.$model"
            />
            <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.nextOfKin.relationship.$errors" :key="index">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>

          <div class="relative w-full mb-3 mt-3">
            <button
              class="bg-emerald-500 flex text-center justify-center text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
              type="button" 
              :disabled="loaders().saveNextOfKin || loaders().updateNextOfKin || v$.nextOfKin.$invalid"
              @click="addNextOfKin"
            >
              <span v-if="loaders().saveNextOfKin || loaders().updateNextOfKin" class="flex loader loader-sm"></span><span v-else>Save</span>
            </button>
          </div>
        </div>
        <div v-else>
          3 next of kins supplied <i class="fas fa-check text-lg text-emerald-600"></i>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import useVuelidate from '@vuelidate/core';
import { 
  required, 
  minLength,
  numeric,
  email
  // decimal, numeric, maxLength 
} from '@vuelidate/validators';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export default {
  props: ["activeBusiness", "activeNextOfKin"],
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      business: {
        name         : {required}, 
        support_email: {required}, 
        logo         : {}, 
        address      : {required}, 
        phone        : {numeric, min: minLength(11)},
        lga          : {},
        city         : {},
        state        : {},
        postal_code  : {},
        country      : {}, 
        about        : {},
        webhook_url  : {},
        document     : {} 
      },
      nextOfKin: {
        name  : {required}, 
        email : {required, email}, 
        phone : {numeric, min: minLength(11)}, 
        relationship : {required}, 
      },
      verification: {
        bvn : {required, numeric, min: minLength(11)}
      }
    }
  },
  watch: {
    activeBusiness: function(business) {
      this.business = business
    },
    activeNextOfKin: function(nextOfKin) {
      this.nextOfKin = nextOfKin
    }
  },
  data() {
    return {
      business: {},
      nextOfKin: {},
      verification: {},
      errors: {}
    }
  },
  methods: {
    ...mapActions([
      "addBusiness", 
      "updateBusiness",
      "saveNextOfKin",
      "updateNextOfKin",
      "deleteNextOfKin",
      "verifyViaBVN"
    ]),
    ...mapGetters(["loaders", "businesses", "nextOfKins", "userProfile", "auth"]),
    saveBusiness() {
      const query = this.business.id ? this.updateBusiness(this.business) : this.addBusiness(this.business);
      query.then(() => {})
    },
    addNextOfKin() {
      const query = this.nextOfKin.id ? this.updateNextOfKin(this.nextOfKin) : this.saveNextOfKin(this.nextOfKin);
      const self = this;
      query.then(() => {
        self.nextOfKin = {}
      })
    },
    verifyByBVN() {
      const data = this.getDetails();
      if(!(Object.values(data).some( e => e))) {
        toaster.info("Please update your profile first")
        return;
      }
      data.bvn = this.verification.bvn;
      this.verifyViaBVN(data).then(() => {}).catch( e => {
        const { message } = e;
        this.errors = { message }
      })
    },
    getDetails() {
      const data = {}
      const profile = this.userProfile();
      
      if ( profile.first_name && profile.last_name) {
        data.firstname = profile.first_name;
        data.lastname = profile.last_name;
      } else {
        const names = this.user.name.split(" ");
        data.firstname = names[0];
        data.lastname = names[1] || ""
      }

      data.dob = profile.dob;
      return data;
    },
  },
};
</script>