import http from "./http";

export const GetMyTransactions = async params => {
    let data = {
        params
    }
    if ( params && params.get_csv) {
        data.responseType = 'blob'
    }

    return await http.get("/transaction/me", data);
}

export const GetTransactions = async params => {
    let data = {
        params
    }
    if ( params && params.get_csv) {
        data.responseType = 'blob'
    }
    
    return await http.post("/transaction/fetch", params, data);
}