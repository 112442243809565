export const getPlans = (
    state,
    plans
) => {
    state.plans = plans;
};

export const getPlanSummary = (state, summary) => {
    state.planSummary = summary
}

export const addPlan = (state, plan) => {
    state.plans = {...state.plans, plan}
}

export const subscibe = (
    state, subscibe
) => {
    state.subscibe = subscibe;
}

export const fetchPlanUser = (state, userPlans) => {
    state.userPlan = userPlans
}

export const updatePlan = (
    state, plan
) => {
    const planIdx = state.plans.findIndex(
        (_plan) => _plan.id === plan.id
    );

    if (planIdx < 0) {
        return;
    }

    state.plans[planIdx] = plan;
}

export const deletePlan = (
    state, planId
) => {
    const plans = state.plans.filter(
        (_plan) => _plan.id === planId
    );

    state.plans = plans;
}

export const updatePlanCoinType = (
    state, coinType
) => {

    const planIdx = state.plans.findIndex(
        (_plan) => _plan.id === coinType.planId
    );

    if (planIdx < 0) {
        return;
    }

    const foundPlan = state.plans[planIdx];

    foundPlan.coin_types = {
        ...foundPlan.coin_types,
        coinType
    }
}

export const removePlanCoinType = (
    state, coinType
) => {

    const planIdx = state.plans.findIndex(
        (_plan) => _plan.id === coinType.planId
    );

    if (planIdx < 0) {
        return;
    }

    const foundPlan = state.plans[planIdx];

    const new_coin_types = foundPlan.coin_types.filter(
        (_coin_types) => _coin_types.id !== coinType.coinTypeId
    );

    foundPlan.coin_types = new_coin_types
}

// export const attachCoinType = (
//   state, subscibe
// ) => {
//   state.subscibe = subscibe;
// }

// export const detachCoinType = (
//   state, subscibe
// ) => {
//   state.subscibe = subscibe;
// }

export const confirmLoan = (state, userPlanId) => {
    state.userPlan = state.userPlan.map(userPlan => userPlan.id === userPlanId ? ({...userPlan, status: "ACTIVE"}) : userPlan)
}

export const declineLoan = (state, userPlanId) => {
    state.userPlan = state.userPlan.map(userPlan => userPlan.id === userPlanId ? ({...userPlan, status: "DECLINED"}) : userPlan)
}

const PlanMutation = {
    getPlans,
    addPlan,
    updatePlan,
    deletePlan,
    subscibe,
    updatePlanCoinType,
    removePlanCoinType,
    fetchPlanUser,
    getPlanSummary,
    confirmLoan,
    declineLoan
    // attachCoinType, 
    // detachCoinType
};

export default PlanMutation;