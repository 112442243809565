<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700">
            Role with Permissions
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <!-- <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
          >
            See all
          </button> -->
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <h3 class="p-4">{{ role.name }}</h3>
      <div class="flex flex-wrap">
        <div class="w-full md:w-1/2 px-4 flex justify-between mb-2" v-for="(permission, i) in role.permissions" :key="i">
          {{ permission.name }}
          <button class="bg-red-500 text-white active:bg-red-600 uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" @click="removePermission(permission)">
            <!-- detach -->
            <i class="fa fa-times"></i>
          </button>
        </div>
      </div>

      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 p-4 font-bold uppercase">
          Add Permission
        </h6>
        <div class="flex flex-wrap">

          <div class="w-full px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Permissions
              </label>
              
              <select v-model="v$.permission.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                <template v-for='(_permission, i) in permissions'>
                  <option v-if="!(role.permissions||[]).includes(_permission.id) && _permission.is_admin" :value="_permission" :key="i">{{ _permission.name }} - <i>({{ _permission.description }})</i></option>
                </template>
              </select>
            </div>
          </div> 
        </div>

        <div class="relative w-full mb-3 px-4 mt-8">
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
            type="button" 
            @click="attachPermission" 
            :disabled="loaders().addPermission"
          >
            Add Permission
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { mapActions, mapGetters } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ /* options */ });

export default {
  props: [
    "selectedRole", 
    "permissions"
  ],
  methods: {
    ...mapActions([
      "addPermission", 
      "detachPermission"
    ]),
    ...mapGetters([
      "loaders"
    ]),
    attachPermission() {
      if ( !this.permission.id ) {
        toaster.show('please select a permission first');
        return false;
      }
      const data = {
        role: this.role,
        permission: this.permission
      };
      if ( !this.role.id ) {
        toaster.show('please select a role first');
        return false;
      }

      const self = this;
      this.addPermission(data).then( () => {
        const exisitingPermission = self.role.permissions.find( p => p.id === data.permission.id)
        if ( !exisitingPermission ) {
          self.role.permissions.push(data.permission)
        }
      });
    },
    removePermission(permission) {
      if (!confirm("confirm to delete")) {
        toaster.show("you cancelled the operation");
      }
      const data = {
        role: this.role,
        permission
      };
      if ( !this.role.id ) {
        toaster.show('please select a plan first');
        return false;
      }
      const self = this;
      this.detachPermission(data).then( () => {
        self.role.permissions = self.role.permissions.filter( p => p.id !== data.permission.id)
      });
    },
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      role: {},
      permission: {}
    }
  },
  validations() {
    return {
      permission: {}
    }
  },
  watch: {
    // whenever question changes, this function will run
    selectedRole(oldRole) {
      this.role = oldRole;
    },
  },
}
</script>
