import http from "./http";

export const GetBusinesses = async() => {
    return await http.get("/manager/businesses");
};

export const GetUserBusinesses = async() => {
    return await http.get("/manager/businesses");
};

export const ConfirmBusiness = async id => {
    return await http.patch(`/business/confirm/${id}`);
};

export const AddBusiness = async data => {
    return await http.post("/business", data);
};

export const UpdateBusiness = async (id, data) => {
    return await http.put(`/business/${id}`, data);
};

export const DeleteBusiness = async id => {
    return await http.delete("/business/" + id);
};

export const AddAppConfig = async data => {
    return await http.post("/app-config", data);
};

export const UpdateAppConfig = async (id, data) => {
    return await http.put(`/app-config/${id}`, data);
};

export const CheckSubdomain = async params => {
    return await http.get(`/dns/check`, {params});
};

export const UploadLogo = async (data, business) => {
    http.defaults.headers = {
      "Content-Type": "multipart/form-data",
      "x-auth-id": "assets",
      Accept: "*/*"
    };
    const res = await http.post(`business/${business}/logo`, data);
    http.defaults.headers = {
      "Content-Type": "application/json",
      Accept: "application/json"
    };
    return res;
  };

  export const UploadBanner = async (data, business) => {
    http.defaults.headers = {
      "Content-Type": "multipart/form-data",
      "x-auth-id": "assets",
      Accept: "*/*"
    };
    const res = await http.post(`business/${business}/banner`, data);
    http.defaults.headers = {
      "Content-Type": "application/json",
      Accept: "application/json"
    };
    return res;
  };
  
export const GetCommission = async params => {
  let data = {
      params
  }
  if ( params && params.get_csv) {
      data.responseType = 'blob'
  }

  return await http.get("/commission", data);
};

export const ReGenerateKey = async (data, business, path) => {
  return await http.patch(`business/${business}/regenerate-key/${path}`, data);
};