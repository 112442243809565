<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16"
  >
    <div class="px-6">
      <div class="mt-3">
        <h3
          class="text-xl font-semibold leading-normal mb-2 text-blueGray-700"
        >
          Connect Credentials
        </h3>
        <div class="mb-2 text-blueGray-600 mt-10 text-xs">
          <p>
            <i class="fas fa-key mr-2 text-xs text-blueGray-400"></i>
            API Key:
          </p>
          <p class="bg-gray-100 py-2 px-4 rounded-sm mt-2 cursor-pointer" @click="clickToCopy(businesses() && businesses().data.length > 0 ? businesses().data[0].uuid : '--')">
            <span >{{ businesses() && businesses().data.length > 0 ? businesses().data[0].uuid : '--' }}</span>
            <br/>
            <span class="text-gray-900"><i class="fas fa-file text-xs text-gray-600"></i> copy</span>
          </p>
        </div>
        <div class="mb-2 text-blueGray-600 text-xs">
          <i class="fas fa-key mr-2 text-xs text-blueGray-400"></i>
          Secret Key:
          <p class="bg-gray-100 py-2 px-4 rounded-sm mt-2 cursor-pointer" @click="toggleModal('generate_secret_key', true)">
            <span >*******</span><br/>
            <span class="text-gray-900"><i class="fas fa-eye text-xs text-gray-600"></i> regenerate</span>
          </p>
        </div>
      </div>
      <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
        <div class="flex flex-wrap justify-center">
          <div class="w-full lg:w-9/12">
            <p class="mb-4 text-xs leading-relaxed text-blueGray-400">
              <i class="fas fa-exclamation-circle text-xs text-orange-500"></i> 
              Kindly ensure that you keep your secret key safe and secure. 
              If you feel there might be a bridge somewhere, click on regenerate 
              to get a new key. It invalidates the previous keys generated.
            </p>
            <!-- <a href="javascript:void(0);" class="font-normal text-emerald-500">
              Show more
            </a> -->
          </div>
        </div>
      </div>
    </div>

    <div v-if="modals.generate_secret_key" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
      <div class="relative w-auto my-6 mx-auto">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-xl font-semibold">
              Generate Secret Key
            </h3>
            <button 
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
              v-on:click="toggleModal('generate_secret_key', false)"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <div v-if="secret_key">
              <p>
                <i class="fas fa-key mr-2 text-xs text-blueGray-400"></i>
                Secret Key:
              </p>
              <p class="bg-gray-100 py-2 px-4 rounded-sm mt-2 cursor-pointer" @click="clickToCopy(secret_key)">
                <span >{{ secret_key }}</span><br/>
                <span class="text-gray-900"><i class="fas fa-file text-xs text-gray-600"></i> copy</span>
              </p>
              <p>
                This key is only visible once. Kindly copy it and keep it somewhere safe and secure. If you lost it, don't worry, you can always regenerate another one.
              </p>
            </div>
            <form v-else>
              <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Authenticate
              </h6>
              <div class="flex flex-wrap">
                <div class="w-full lg:w-12/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      :disabled="loaders().reGenerateKey"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150 mb-2"
                      v-model="v$.password.$model"
                      @keyup.enter="generateKey"
                    />

                    <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.password.$errors" :key="index">
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="relative w-full mb-3 px-4 mt-3 flex justify-end">
                <app-button
                  :classes="'bg-emerald-500 w-full flex text-center text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-4 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150'"
                  type="button" 
                  :disabled="loaders().reGenerateKey"
                  @click="generateKey"
                  title="Generate Key"
                  :loading="loaders().reGenerateKey"
                ></app-button>
              </div>
            </form>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">
            <button 
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
              v-on:click="toggleModal('generate_secret_key', false)"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="modals.generate_secret_key" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-40 h-screen top-0 left-0 bg-black"></div>

  </div>
</template>
<script>
import team2 from "@/assets/img/team-2-800x800.jpg";
import { mapActions, mapGetters } from "vuex";
import { required } from '@vuelidate/validators';
import { createToaster } from "@meforma/vue-toaster";
import AppButton from '@/components/Form/AppButton.vue';
import useVuelidate from '@vuelidate/core';
const toaster = createToaster({ /* options */ });

export default {
  components: {
    AppButton
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      password: {required},
    }
  },
  data() {
    return {
      team2,
      modals: {},
      password: "",
      secret_key: null
    };
  },
  methods: {
    ...mapGetters(["businesses", "loaders"]),
    ...mapActions(["reGenerateKey"]),
    clickToCopy(text) {
      navigator.clipboard.writeText(text).then(function() {
        toaster.success('Copied to clipboard!');
      }, function(err) {
        toaster.show('Could not copy text: ', err);
      });
    },
    toggleModal(modalName, isvisible) {
      this.modals[modalName] = isvisible
    },
    generateKey() {
      const data = {
        password: this.password,
        path: 'test',
        business_id: this.businesses() && this.businesses().data.length > 0 ? this.businesses().data[0].id : null
      }
      const self = this
      this.reGenerateKey(data).then( res => {
        self.secret_key = res;
      });
    }
  }
};
</script>
