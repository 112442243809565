<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xs font-bold">{{ userPlan.user?.name }} | {{ userPlan.plan?.name }} Plan</h6>
        <button
          class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          @click="(e) => $emit('close', e)"
        >
          <i class="fa fa-times"></i> 
        </button>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          User Information
        </h6>
        <div class="flex flex-wrap">
          <div class="relative w-full mb-3">
            {{ userPlan.user?.name }}
            <br/>
            <small>
              {{ userPlan.user?.email }}
            </small>
          </div>
        </div>

        <template v-if="userPlan.user_purchased_coins?.length > 0">
          <hr class="my-4 border-b-1 border-blueGray-300" />
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            Coin Information
          </h6>
          <div class="flex flex-wrap">
            <div class="w-full">
              <div class="relative w-full mb-3">
                <table class="items-center w-full bg-transparent border-collapse">
                  <thead class="thead-light">
                  </thead>
                  <tbody>
                    <tr v-for="(coin, i) in ( userPlan.user_purchased_coins )" :key="i" class="cursor-pointer">
                      <th
                        class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex"
                      >
                        <img
                          :src="coin.purchased_coin.logo"
                          :alt="coin.purchased_coin.symbol"
                          class="w-5 h-5 rounded-full border-2 border-blueGray-50 shadow" 
                        />
                        <div>
                          {{ coin.purchased_coin?.symbol }} <br/>
                          <small>{{ coin.purchased_coin?.coin }}</small>
                        </div>
                        
                      </th>
                      <td
                        class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                        {{ coin.price_bought.toFixed(2) }}
                      </td>
                      <td
                        class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                        <!-- <div class="relative w-full">
                          <select disabled class="border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                            <option :selected="coin.status === status" v-for="(status, i) in ['INITIATED', 'TRADING', 'PENDING', 'FAILED', 'APPROVED', 'DECLINED', 'DUE', 'DEFAULT', 'COMPLETED', 'WITHDRAWN']" :key="i" :value="status">
                            {{ status }} <small>({{ status }})</small>
                            </option>
                          </select>
                        </div> -->
                        {{ coin.status }}
                      </td>
                      <td
                        class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                      <button
                        class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button" 
                        @click="stopTrade(coin)"
                      >
                        Decline 
                      </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="userPlan.savings?.length > 0">
          <hr class="my-4 border-b-1 border-blueGray-300" />
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            Savings Plan
          </h6>
          <div class="flex flex-wrap">
            <div class="w-full">
              <div class="relative w-full mb-3">
                <table class="items-center w-full bg-transparent border-collapse">
                  <thead class="thead-light">
                  </thead>
                  <tbody>
                    <tr v-for="(saving, i) in ( userPlan.savings )" :key="i" class="cursor-pointer">
                      <th
                        class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex"
                      >
                        <div>
                          {{ userPlan.currency }} {{ saving.amount_paid.toFixed(2) }} | {{ userPlan.currency }} {{ saving.amount.toFixed(2) }} <br/>
                          <small>{{ saving.due_at }}</small>
                        </div>
                        
                      </th>
                      <td
                        class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                        {{ saving.status }}
                      </td>
                      <td
                        class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                      <button
                        class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button" 
                        @click="nudgeSavings(saving)"
                      >
                        Nudge 
                      </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="userPlan.loans?.length > 0">
          <hr class="my-4 border-b-1 border-blueGray-300" />
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            Loan Repayment Plan
          </h6>
          <div class="flex flex-wrap">
            <div class="w-full">
              <div class="relative w-full mb-3">
                <table class="items-center w-full bg-transparent border-collapse">
                  <thead class="thead-light">
                  </thead>
                  <tbody>
                    <tr v-for="(loan, i) in ( userPlan.loans )" :key="i" class="cursor-pointer">
                      <th
                        class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex"
                      >
                        <div>
                          {{ userPlan.currency }} {{ Math.round(loan.amount_paid * 100)/100 }} | {{ userPlan.currency }} {{ loan.amount.toFixed(2) }} <br/>
                          <small>{{ loan.due_at }}</small>
                        </div>
                        
                      </th>
                      <td
                        class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                        {{ loan.status }}
                      </td>
                      <td
                        class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                      <button
                        class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button" 
                        @click="debitLoan(loan)"
                      >
                        Debit 
                      </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="userPlan.investments?.length > 0">
          <hr class="my-4 border-b-1 border-blueGray-300" />
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            Investment Payment Plan
          </h6>
          <div class="flex flex-wrap">
            <div class="w-full">
              <div class="relative w-full mb-3">
                <table class="items-center w-full bg-transparent border-collapse">
                  <thead class="thead-light">
                  </thead>
                  <tbody>
                    <tr v-for="(investment, i) in ( userPlan.investments )" :key="i" class="cursor-pointer">
                      <th
                        class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex"
                      >
                        <div>
                          {{ userPlan.currency }} {{ investment.amount_paid.toFixed(2) }} <br/>
                          <small>{{ investment.due_at }}</small>
                        </div>
                        
                      </th>
                      <td
                        class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                      {{ userPlan.currency }} {{ investment.amount.toFixed(2) }}
                      </td>
                      <td
                        class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                        {{ investment.status }}
                      </td>
                      <td
                        class="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                      >
                      <button
                        class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button" 
                        @click="{}"
                      >
                        Pay 
                      </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </template>

      </form>
    </div>

    <div v-if="showDebitModal" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
      <div class="relative w-auto my-6 mx-auto max-w-sm">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-xl font-semibold">
              Debit Loan
            </h3>
            <button 
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
              v-on:click="showDebitModal=false"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <div class="w-full px-4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                Debit Amount
                </label>
                <input
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                  v-model="v$.debit_amount.$model"
                  :disabled="loaders().repayLoan"
                />
                <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.debit_amount.$errors" :key="index">
                  <div class="error-msg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">
            <button 
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
              v-on:click="showDebitModal=false"
            >
              Close
            </button>
            <button class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 flex rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
            type="button" 
            :disabled="loaders().repayLoan"
            @click="processDebitLoan">
              Debit
              <span v-if="loaders().repayLoan" class="flex loader loader-sm"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showDebitModal" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-40 h-screen top-0 left-0 bg-black"></div>

  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, decimal } from '@vuelidate/validators';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
// import { Loader } from 'google-maps';
const toaster = createToaster({ /* options */ });

export default {
  props: [ "selectedPlan" ],
  components: {
  },
  setup () {
    return { v$: useVuelidate() }
  },
  watch: {
    selectedPlan: function(plan) {
      this.userPlan = plan
    }
  },
  data() {
    return {
      userPlan: this.selectedPlan || {},
      loan: {},
      showDebitModal: false,
      debit_amount: 0,
    }
  },
  validations() {
    return {
      userPlan: {
        amount: { required, decimal },
        base_currency: { required },
        rate: { required, decimal },
        status: { required },
      },
      debit_amount: { required, decimal }
    }
  },
  methods: {
    ...mapGetters(["coinType", "loaders"]),
    ...mapActions([ "attachCoinType", "repayLoan", "detachCoinType", "updatePlanUser", "terminateTrade" ]),
    ...mapMutations(["updatePlanCoinType", "removePlanCoinType"]),
    attachCoin() {
      const data = {
        coin_type_id: this.coin_type.coin_type.id,
        quantity: this.coin_type.quantity
      };
      if ( !this.plan.id ) {
        toaster.show('please select a plan first');
        return false;
      }
      data.plan_id = this.plan.id;
      const self = this;
      this.attachCoinType(data).then( res => {
        console.log(res);
        const newCoinType = {
          planId: self.plan.id,
          id: self.coin_type.coin_type.id,
          name: self.coin_type.coin_type.name,
          symbol: self.coin_type.coin_type.symbol,
          pivot: {
            quantity: data.quantity
          }
        };

        this.updatePlanCoinType(newCoinType)
      });
    },
    detachCoin(coinType) {
      if (!confirm("confirm to delete")) {
        toaster.show("you cancelled the operation");
      }
      const data = {
        coin_type_id: coinType.id,
        quantity: this.coin_type.quantity
      };
      if ( !this.plan.id ) {
        toaster.show('please select a plan first');
        return false;
      }
      data.plan_id = this.plan.id;
      const self = this;
      this.detachCoinType(data).then( res => {
        console.log(res)
        const newCoinType = {
          planId: self.plan.id,
          coinTypeId: coinType.id,
        };

        this.removePlanCoinType(newCoinType)
      });
    },
    updateExistingPlan() {
      const data = this.userPlan;
      this.updatePlanUser(data)
    },
    stopTrade(userPurchasedCoin) {
      if ( confirm(" Are you sure you want to terminate this trade? "))
        this.terminateTrade(userPurchasedCoin.id)
    },
    nudgeSavings(saving) {
      console.log(saving)
      toaster.info("sent notification")
    },
    debitLoan(loan) {
      this.loan = loan,
      this.showDebitModal = true
      toaster.info("initiated")
    },
    processDebitLoan() {
      const self = this
      this.repayLoan({id: this.loan.id, amount: this.debit_amount})
        .then( () => {
          self.loan.amount_paid = self.debit_amount;
          self.showDebitModal=false
        })
    },
    // 
    // async showLocation(lng, lat) {
    //   const options = {/* todo */};
    //   const loader = new Loader('AIzaSyB_kGyDT_-Rx9zdi6xRCqoigWdHewM8HYo', options);
      
    //   const google = await loader.load();
    //   new google.maps.Map(document.getElementById('map'), {
    //       center: {lat: +lat, lng: +lng},
    //       zoom: 8,
    //   });
    //   // console.log(map)
    // }
  }
}
</script>