<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
      <card-transaction />
    </div>
    <!-- <div class="w-full mb-12 px-4">
      <card-transaction color="dark" />
    </div> -->
  </div>
</template>
<script>
import CardTransaction from "@/components/Cards/CardTransaction.vue";

export default {
  components: {
    CardTransaction,
  },
};
</script>
