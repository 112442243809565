<template>
  <!-- Header -->
  <div class="relative bg-blueGray-100 text-emerald-800 md:pt-4 pb-4 pt-2">
    <div class="px-4 md:px-10 mx-auto w-full" v-if="has('plan.summary.view')">
      <div>
        <!-- Card stats -->
        <div class="flex flex-wrap">
          <div v-for="(stat, i) in Object.values(planSummary())" :key="i" class="w-full lg:w-6/12 xl:w-3/12 px-4 mt-1">
            <card-stats
              :statSubtitle="`${stat.status} ${type.toUpperCase()}`"
              :statTitle="formatter().format(stat.amount || 0)"
              :statArrow="`${'up'}`"
              :statCount="`${stat.total}`"
              :statPercentColor="`text-orange-500`"
              :statDescripiron="`${stat?.status?.toLowerCase()} ${type} count`"
              statIconName="far fa-chart-bar"
              statIconColor="bg-red-500"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-wrap mt-4">
        <div class="w-full lg:w-3/12 px-4 text-emerald-800">
          Showing results for
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <select @change="fetchFilteredSummary" v-model="type" class="border-0 px-3 py-3 placeholder-blueGray-300 text-emerald-800 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
              <option v-for='(t, i) in ["", "loan", "savings", "investment"]' :key="i" :value="t">
              {{ t }}
              </option>
            </select>
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative w-full mb-3 flex align-middle justify-center">
            <button
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
                type="button" 
                @click="load24HrsSummary"
              >
              24hrs Ago
            </button>

            <button
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
                type="button"
                @click="load1WeekSummary" 
              >
              1 week ago
            </button>

            <button
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
                type="button" 
                @click="load1MonthSummary"
              >
              30 days ago
            </button>

            <button
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
                type="button" 
                @click="load3MonthSummary"
              >
              3 Months ago
            </button>

          </div>
        </div>
        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <select @change="fetchFilteredSummary" v-model="status" class="border-0 px-3 py-3 placeholder-blueGray-300 text-emerald-800 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
              <option v-for="(t, i) in ['', 'INITIATED', 'PENDING', 'FAILED', 'ACTIVE', 'DECLINED', 'DUE', 'DEFAULT', 'COMPLETED', 'WITHDRAWN', 'APPROVED']" :key="i" :value="t">
              {{ t }}
              </option>
            </select>
          </div>
        </div>

        <div class="w-full lg:w-3/12 px-4">
          <div class="relative w-full mb-3">
            <input @keyup="asyncFetchFilteredSearch" v-model="search" class="border-0 px-3 py-3 placeholder-blueGray-300 text-emerald-800 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150" />
          </div>
        </div>
        
        <div class="w-full flex lg:w-4/12 px-4">
          <div class="w-6/12">
            <input 
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-emerald-800 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
              id="start-date-range"
              type="text"
              placeholder="start date"
            />
          </div>
          
          <div class="w-6/12 ml-1">
            <input 
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-emerald-800 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150" 
              id="end-date-range"
              type="text"
              placeholder="end date"
            />
          </div>
        </div>

        <div class="px-4">
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
            type="button" 
            @click="downloadSummary"
          >
            Download
          </button>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import CardStats from "@/components/Cards/CardStats.vue";
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment'

import datepicker from 'js-datepicker'

export default {
  components: {
    CardStats
  },
  created() {
    
  },
  mounted() {
    this.getPlanSummary({})
    const self = this
    setTimeout( function() {
      self.start_date = datepicker("#start-date-range", {
        id: "range",
        onSelect: (instance, date) => {
          console.log(instance)
          self.from = date;
          self.loadCustomDateSummary()
        },
        // disabler: date => moment(new Date).isBefore(moment(date))
      });

      self.end_date = datepicker("#end-date-range", {
        id: "range",
        onSelect: (instance, date) => {
          console.log(instance)
          self.to = date;
          self.loadCustomDateSummary()
        },
        // disabler: date => moment(new Date).isBefore(moment(date))
      })
    }, 1000)
  },
  data() {
    return {
      type: "",
      status: "",
      search: "",
      params: {},
      timer: null,
      start_date: null,
      end_date: null,
      from: null,
      to: null
    }
  },
  methods: {
    ...mapActions(["getPlanSummary"]),
    ...mapGetters(["planSummary"]),
    // Create our number formatter.
    formatter() {
      return new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
    },
    paramsToQuery(param = null) {
      return '?' + Object.entries(param || this.params).filter(([,v]) => v && v !== "").map(([k, v]) => `${k}=${v}`).join("&")
    },
    fetchFilteredSummary() {
      this.params = {
        ...this.params,
        type: this.type,
        status: this.status,
        search: this.search,
      }
      this.$emit('statChange', this.params)
      this.getPlanSummary({q: this.paramsToQuery(this.params)});
    },
    load24HrsSummary() {
      const to = moment(new Date).format("YYYY-MM-DD")
      const from = moment(new Date).subtract(1, 'day').format("YYYY-MM-DD")
      this.params = {...this.params, type: this.type, to, from}
      this.$emit('statChange', this.params)
      this.getPlanSummary({q: this.paramsToQuery(this.params)})
    },
    load1WeekSummary() {
      const to = moment(new Date).format("YYYY-MM-DD")
      const from = moment(new Date).subtract(1, 'week').format("YYYY-MM-DD")
      this.params = {...this.params, type: this.type, to, from}
      this.$emit('statChange', this.params)
      this.getPlanSummary({q: this.paramsToQuery(this.params)})
    },
    load1MonthSummary() {
      const to = moment(new Date).format("YYYY-MM-DD")
      const from = moment(new Date).subtract(1, 'month').format("YYYY-MM-DD")
      this.params = {...this.params, type: this.type, to, from}
      this.$emit('statChange', this.params)
      this.getPlanSummary({q: this.paramsToQuery(this.params)})
    },
    load3MonthSummary() {
      const to = moment(new Date).format("YYYY-MM-DD")
      const from = moment(new Date).subtract(3, 'month').format("YYYY-MM-DD")
      this.params = {...this.params, type: this.type, to, from}
      this.$emit('statChange', this.params)
      this.getPlanSummary({q: this.paramsToQuery(this.params)})
    },
    loadCustomDateSummary() {
      const to = moment(this.to || new Date).format("YYYY-MM-DD")
      const from = moment(this.from || new Date).format("YYYY-MM-DD")
      this.params = {...this.params, type: this.type, to, from}
      this.$emit('statChange', this.params)
      this.getPlanSummary({q: this.paramsToQuery(this.params)})
    },
    downloadSummary() {
      this.params = {...this.params, get_csv: true}
      this.$emit('statChange', this.params)
    },
    asyncFetchFilteredSearch() {
      if ( this.timer ) {
        clearTimeout(this.timer);
        this.timer = null
      }
      const self = this
      this.timer = setTimeout(function () {
        self.fetchFilteredSummary()
      }, 1000)
    }
  }
};
</script>
