import { camelToSnakeCase, snakeToCamelCase } from ".";

export const getSnakeCaseInput = (inputs) => {
  const res = {};
  for (const i in inputs) {
    const val = inputs[i];
    if (val && val !== 0) res[camelToSnakeCase(i)] = val;
  }
  return res;
};

export const getCamelCaseInput = (inputs) => {
  const res = {};
  for (const i in inputs) {
    const val = inputs[i];
    if (val && val !== 0) res[snakeToCamelCase(i)] = val;
  }
  return res;
};


export const getImageDimensions = async (file) => {
  let img = new Image();
  img.src = URL.createObjectURL(file);
  await img.decode();
  let width = img.width;
  let height = img.height;
  return {
      width,
      height,
  }
};