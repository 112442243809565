<template>
    <div class="relative flex-auto">
        <table>
            <tbody>
                <tr
                v-for="([k, v], i) in Object.entries(data)" :key="i"
                class="border-b-1"
                >
                    <template v-if="!exclude.includes(getKey(k)) && !excludeAll.includes(k)">
                        <td
                        class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-1 text-left"
                        >{{ k.split("_").join(" ") }}</td> 
                        <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs text-left flex items-center">
                        <template v-if="(typeof v === 'object') && v !== null">
                            <recursive-table 
                                v-bind:data="v"
                                v-bind:exclude="exclude"
                                v-bind:images="images"
                                v-bind:links="links"
                                v-bind:tels="links"
                                v-bind:emails="links"
                                v-bind:dates="dates"
                                v-bind:excludeAll="excludeAll"
                                v-bind:imagesAll="imagesAll"
                                v-bind:linksAll="linksAll"
                                v-bind:telsAll="linksAll"
                                v-bind:emailsAll="linksAll"
                                v-bind:datesAll="datesAll"
                                v-bind:parent="getKey(k)"
                            />
                        </template>
                        <template v-else-if="(Array.isArray(v)) && v !== null">
                            <recursive-table 
                                v-for="(b, j) in v"
                                :key="j"
                                v-bind:data="b"
                                v-bind:exclude="exclude"
                                v-bind:images="images"
                                v-bind:links="links"
                                v-bind:tels="links"
                                v-bind:emails="links"
                                v-bind:excludeAll="excludeAll"
                                v-bind:imagesAll="imagesAll"
                                v-bind:linksAll="linksAll"
                                v-bind:telsAll="linksAll"
                                v-bind:emailsAll="linksAll"
                                v-bind:datesAll="datesAll"
                                v-bind:parent="getKey(k)"
                            />
                        </template>
                        <template v-else>
                            <span v-if="v==='' || v===null">
                                <i>nill</i>
                            </span>
                            <span v-else-if="images.includes(getKey(k)) || imagesAll.includes(k)">
                                <img :src="v" alt="image" :width="imageWidth"/>
                            </span>
                            <span v-else-if="links.includes(getKey(k)) || linksAll.includes(k)">
                                <a :href="v">{{ v }}</a>
                            </span>
                            <span v-else-if="tels.includes(getKey(k)) || telsAll.includes(k)">
                                <a :href="`tel:${v}`">{{ v }}</a>
                            </span>
                            <span v-else-if="emails.includes(getKey(k)) || emailsAll.includes(k)">
                                <a :href="`mailto:${v}`">{{ v }}</a>
                            </span>
                            <span v-else-if="dates.includes(getKey(k)) || datesAll.includes(k)">
                                {{ moment(v).format("DD MMM YY hh:mm") }}
                            </span>
                            <span v-else>
                                {{ v }}
                            </span>
                        </template>
                        </td>
                    </template>
                </tr>
            </tbody>
        </table>
    </div>
</template>
  
<script>
  import moment from "moment"

  export default {
    name: 'recursive-table',
    created() {
        this.moment = moment
    },
    props: {
        data: {
            require: true,
            type: Object
        },
        exclude: {
            require: false,
            type: Array,
            default: () => []
        },
        images: {
            require: false,
            type: Array,
            default: () => []
        },
        links: {
            require: false,
            type: Array,
            default: () => []
        },
        tels: {
            require: false,
            type: Array,
            default: () => []
        },
        emails: {
            require: false,
            type: Array,
            default: () => []
        },
        dates: {
            require: false,
            type: Array,
            default: () => []
        },
        excludeAll: {
            require: false,
            type: Array,
            default: () => []
        },
        imagesAll: {
            require: false,
            type: Array,
            default: () => []
        },
        linksAll: {
            require: false,
            type: Array,
            default: () => []
        },
        telsAll: {
            require: false,
            type: Array,
            default: () => []
        },
        emailsAll: {
            require: false,
            type: Array,
            default: () => []
        },
        datesAll: {
            require: false,
            type: Array,
            default: () => []
        },
        imageWidth: {
            require: false,
            type: String,
            default: "25"
        },
        parent: {
            require: false,
            type: String,
            default: ""
        },
    },
    data() {
      return {}
    },
    methods: {
        getKey: function(k) {
            return this.parent ? `${this.parent}.${k}` : k;
        }
    }
  }
</script>