import http from "./http";

export const GetQuestions = async() => {
    return await http.get("/questions");
}

export const AddQuestion = async data => {
    return await http.post("/questions", data);
}

export const EditQuestion = async(id, data) => {
    return await http.put("/questions/" + id, data);
}

export const DeleteQuestion = async id => {
    return await http.delete("/questions/" + id);
}

export const SelectAnswer = async(id) => {
    return await http.get("/user/answers/" + id);
}


export const AddAnswer = async data => {
    return await http.post("/answers", data);
}

export const EditAnswer = async(id, data) => {
    return await http.put("/answers/" + id, data);
}

export const DeleteAnswer = async id => {
    return await http.delete("/answers/" + id);
}


export const AddReview = async data => {
    return await http.post("/reviews", data);
}

export const EditReview = async(id, data) => {
    return await http.put("/reviews/" + id, data);
}

export const DeleteReview = async id => {
    return await http.delete("/reviews/" + id);
}