<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700">
            Permissions
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <!-- <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
          >
            See all
          </button> -->
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <div class="flex flex-wrap">
        <div 
          class="w-full md:w-1/2 px-4 flex justify-between pb-2" 
          :class="`${_permission.is_active ? 'text-emerald-500 hover:text-emerald-600' : 'text-red-500 hover:text-red-700'} ${_permission.is_blocked ? 'line-through italic': ''}`" 
          v-for="(_permission, i) in permissions" :key="i"
        >
          <span class="text-xs text-gray-500">
            {{ _permission.name }} | 
            <i>{{ _permission.description || "--" }}</i>
          </span>

          <span class="cursor-pointer text-gray-500">
            <small class="text-xs">
              ({{ _permission.is_admin ? "admin" : "public"}})
            </small>

            <button 
              class="bg-emerald-500 text-white active:bg-emerald-600 uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" 
              @click="permission=_permission"
            >
              <!-- detach -->
              <i class="fas fa-pen ml-2 text-xs"></i>
            </button>
          </span>
        </div>
      </div>

      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 p-4 font-bold uppercase">
          Update Permission
        </h6>
        <div class="flex flex-wrap">

          <div class="w-full px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Permissions
              </label>
              
              <select v-model="v$.permission.$model" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                <template v-for='(_permission, i) in permissions'>
                  <option v-if="true" :value="_permission" :key="i">{{ _permission.name }}</option>
                </template>
              </select>
            </div>
          </div> 

          <div class="w-full px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Description
              </label>
              <textarea
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                rows="4"
                v-model="v$.permission.description.$model"
              ></textarea
              >
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.permission.description.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              Is Admin Permission
              </label>
              <label class="inline-flex items-center cursor-pointer">
                <input id="opt-out" type="checkbox" v-model="v$.permission.is_admin.$model" class="form-checkbox border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150">
                  <span class="ml-2 text-sm font-semibold text-blueGray-600"> {{ permission.is_admin ? 'admin' : 'public' }} </span>
                </label>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.permission.is_admin.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              Is Active
              </label>
              <label class="inline-flex items-center cursor-pointer">
                <input id="opt-out" type="checkbox" v-model="v$.permission.is_active.$model" class="form-checkbox border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150">
                  <span class="ml-2 text-sm font-semibold text-blueGray-600"> {{ permission.is_active ? 'active': 'in-active' }} </span>
                </label>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.permission.is_active.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>

          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
              Is Blocked
              </label>
              <label class="inline-flex items-center cursor-pointer">
                <input id="opt-out" type="checkbox" v-model="v$.permission.is_blocked.$model" class="form-checkbox border-1 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150">
                  <span class="ml-2 text-sm font-semibold text-blueGray-600"> {{ permission.is_blocked ? 'blocked': 'unblocked' }} </span>
                </label>
              <div class="input-errors text-xs text-danger mt-2" v-for="(error, index) of v$.permission.is_blocked.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          
        </div>

        <div class="relative w-full mb-3 px-4 mt-8">
          <button
            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
            type="button" 
            @click="persistPermission" 
            :disabled="loaders().updatePermission"
          >
            Update Permission
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { mapActions, mapGetters } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({});

export default {
  props: [
    "permissions"
  ],
  methods: {
    ...mapActions([
      "updatePermission"
    ]),
    ...mapGetters([
      "loaders"
    ]),
    persistPermission() {
      if ( !this.permission.id ) {
        toaster.show('please select a permission first');
        return false;
      }
      this.updatePermission(this.permission)
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      permission: {}
    }
  },
  validations() {
    return {
      permission: {
        description: {},
        is_active: {},
        is_blocked: {},
        is_admin: {}
      }
    }
  }
}
</script>
