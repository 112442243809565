<template>
    <button :class="`btn ${ classes || 'btn-success w-100'}`" :disabled="disabled || false" type="button">
        <div v-if="loading" class="flex loader loader-sm text-light" role="status"></div>
        <div v-else>{{ title || 'Submit' }}</div>
    </button>
</template>

<script>

export default {
  name: 'AppButton',
  props: ['title', 'loading', 'disabled', 'classes'],
  methods: {
      clicked(e) {
          this.$emit('click', e);
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
