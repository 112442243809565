import http from "./http";

export const GetSummary = async data => {
    return await http.post("/summary", data);
}

export const GetDashboard = async data => {
    return await http.get("/summary/dashboard", data);
}

export const GetBalances = async data => {
    return await http.get("/summary/balances", data);
}