import {
    GetQuestions,
    SelectAnswer,
    AddAnswer, 
    AddQuestion, 
    DeleteAnswer, 
    DeleteQuestion, 
    EditAnswer, 
    EditQuestion,
    AddReview,
    EditReview,
    DeleteReview
} from "@/services/insight";

export const getQuestions = (context) => {
    context.commit("updateLoader", { fetchQuestions: true });
    return new Promise((resolve, reject) => {
        GetQuestions()
            .then(res => {
                const result = res.data.data
                context.commit("fetchQuestions", result);
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { fetchQuestions: false });
            });
    });
}

export const addQuestion = (context, data) => {
    context.commit("updateLoader", { addQuestion: true });
    return new Promise((resolve, reject) => {
        AddQuestion(data)
            .then(res => {
                const result = res.data.data
                context.commit("addQuestion", result);
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { addQuestion: false });
            });
    });
}

export const editQuestion = (context, data) => {
    context.commit("updateLoader", { editQuestion: true });
    return new Promise((resolve, reject) => {
        const id = data.id;
        delete data.id;
        EditQuestion(id, data)
            .then(res => {
                const result = res.data.data
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { editQuestion: false });
            });
    });
}

export const deleteQuestion = (context, id) => {
    context.commit("updateLoader", { deleteQuestion: true });
    return new Promise((resolve, reject) => {
        DeleteQuestion(id)
            .then(res => {
                const result = res.data.data
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { deleteQuestion: false });
            });
    });
}

export const addAnswer = (context, data) => {
    context.commit("updateLoader", { addAnswer: true });
    return new Promise((resolve, reject) => {
        AddAnswer(data)
            .then(res => {
                const result = res.data.data
                context.commit("addAnswer", result);
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { addAnswer: false });
            });
    });
}

export const editAnswer = (context, data) => {
    context.commit("updateLoader", { editAnswer: true });
    return new Promise((resolve, reject) => {
        const id = data.id;
        delete data.id;
        EditAnswer(id, data)
            .then(res => {
                const result = res.data.data
                context.commit("editAnswer", result);
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { editAnswer: false });
            });
    });
}
export const deleteAnswer = (context, id) => {
    context.commit("updateLoader", { deleteAnswer: true });
    return new Promise((resolve, reject) => {
        DeleteAnswer(id)
            .then(res => {
                const result = res.data.data;
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { deleteAnswer: false });
            });
    });
}

export const selectAnswer = (context, id) => {
    context.commit("updateLoader", { selectAnswer: true });
    return new Promise((resolve, reject) => {
        SelectAnswer(id)
            .then(res => {
                const result = res.data.data;
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { selectAnswer: false });
            });
    });
}

export const addReview = (context, data) => {
    context.commit("updateLoader", { addReview: true });
    return new Promise((resolve, reject) => {
        AddReview(data)
            .then(res => {
                const result = res.data.data
                // context.commit("addReview", result);
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { addReview: false });
            });
    });
}

export const editReview = (context, data) => {
    context.commit("updateLoader", { editReview: true });
    return new Promise((resolve, reject) => {
        const id = data.id;
        delete data.id;
        EditReview(id, data)
            .then(res => {
                const result = res.data.data
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { editReview: false });
            });
    });
}

export const deleteReview = (context, id) => {
    context.commit("updateLoader", { deleteReview: true });
    return new Promise((resolve, reject) => {
        DeleteReview(id)
            .then(res => {
                const result = res.data.data
                resolve(result)
            })
            .catch(err => {
                console.log(err);
                reject(err)
            })
            .finally(() => {
                // stop loaders
                context.commit("updateLoader", { deleteReview: false });
            });
    });
}

const QuestionAction = {
    getQuestions,
    addQuestion,
    editQuestion,
    deleteQuestion,
    addAnswer,
    editAnswer,
    deleteAnswer,
    selectAnswer,
    addReview,
    editReview,
    deleteReview,
};

export default QuestionAction;