<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <!-- <hr class="mt-6 border-b-1 border-blueGray-300" /> -->
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Business</h6>
        <button
          class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          @click="(e) => $emit('close', e)"
        >
          <i class="fa fa-times"></i> 
        </button>
      </div>
    </div>
    <div class="flex-auto lg:px-10 py-10 pt-0">
      <div class="px-4 mt-4">
        {{ business.address }}<br/>
        {{ business.city }} | {{ business.country }}
      </div>
      <hr/>
      <div>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ business.phone }}
        </h6>
      </div>
      <hr/>
      <div class="relative flex-auto">
        <h6 class="text-blueGray-500 text-sm px-4 py-4 font-bold">Business Details</h6>
        <recursive-table
          :data="business"
          :excludeAll="['id', 'user_id', 'business_id']"
          :imagesAll="['logo', 'banner', 'image']"
          :telsAll="['phone']"
          :emailsAll="['email', 'support_email']"
          :datesAll="['created_at', 'updated_at']"
        />
      </div>

      <button 
        class="text-gray-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
        v-on:click="isMapVisible=!isMapVisible"
      >
        <span v-if="isMapVisible">Hide Map</span><span v-else>Show Map</span>
      </button>
          
      <map-pan :longitude="business.user.profile?.longitude" :latitude="business.user.profile?.latitude" v-if="isMapVisible" ></map-pan>
      <div class="m-4 text-sm" v-else>
          Live Location:  {{ business.user.profile?.longitude }}, {{ business.user.profile?.latitude }}
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RecursiveTable from "@/components/Tables/RecursiveTable.vue";
import MapPan from "@/components/Maps/MapPan.vue";

export default {
  props: [ "activeBusiness" ],
  components: {
    RecursiveTable,
    MapPan
  },
  data() {
    return {
      business: this.activeBusiness,
      isMapVisible: false
    }
  },
  watch: {
    activeBusiness: function(business) {
      this.business = business
    }
  },
  methods: {
    ...mapGetters(["loaders"]),
  }
}
</script>