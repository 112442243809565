import http from "./http";

export const GetItems = async (params = {}) => {
    let data = {
        params
    }
    if ( params && params.get_csv) {
        data.responseType = 'blob'
    }

    return await http.get("/item", data);
};

export const AddItem = async data => {
    return await http.post("/item", data);
};

export const UpdateItem = async (id, data) => {
    return await http.put(`/item/${id}`, data);
};

export const DeleteItem = async id => {
    return await http.delete("/item/" + id);
};

export const UploadThumbnail = async (data, item) => {
    http.defaults.headers = {
      "Content-Type": "multipart/form-data",
      "x-auth-id": "assets",
      Accept: "*/*"
    };
    const res = await http.post(`item/${item}/logo`, data);
    http.defaults.headers = {
      "Content-Type": "application/json",
      Accept: "application/json"
    };
    return res;
};

  export const UploadBanner = async (data, item) => {
    http.defaults.headers = {
      "Content-Type": "multipart/form-data",
      "x-auth-id": "assets",
      Accept: "*/*"
    };
    const res = await http.post(`item/${item}/banner`, data);
    http.defaults.headers = {
      "Content-Type": "application/json",
      Accept: "application/json"
    };
    return res;
};