import AccountGetter from "./account.getter";
import LoaderGetter from "./loader.getter";
import QuestionGetter from "./question.getter";
import NotificationGetter from "./notification.getter";
import TransactionGetter from "./transaction.getter";
import UserGetter from "./user.getter";
import PlanGetter from "./plan.getter";
import PredictionGetter from "./predictions.getter";
import SettingsGetter from "./settings.getter";
import BankAccountGetter from "./bankAccount.getter";
import WithdrawGetter from "./withdraw.getter";
import ToastGetter from "./toast.getter";
import CoinGetter from "./coin.getter";
import MetricsGetter from "./metrics.getter";
import CurrencyGetter from "./currency.getter";
import RoleGetter from "./role.getter";
import BusinessGetter from "./business.getter";
import ItemGetter from "./item.getter";

const getters = {
    ...SettingsGetter,
    ...TransactionGetter,
    ...UserGetter,
    ...PlanGetter,
    ...LoaderGetter,
    ...NotificationGetter,
    ...AccountGetter,
    ...QuestionGetter,
    ...PredictionGetter,
    ...BankAccountGetter,
    ...WithdrawGetter,
    ...ToastGetter,
    ...CoinGetter,
    ...MetricsGetter,
    ...CurrencyGetter,
    ...RoleGetter,
    ...BusinessGetter,
    ...ItemGetter
};

export default getters;